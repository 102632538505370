/* eslint-disable no-console */
import { globalVueErrorsHandler } from '#root/src/utils/handleErrors/handleErrors';

export const cdekConsoleLog = (...args: any) => console.log(...args);
export const cdekConsoleError = (...args: any) => {
  console.error(...args);

  // todo: заменнить cdekConsoleError на logException, оставить в cdekConsoleError только логирование,
  //  унести globalVueErrorsHandler в более подходящее место, см. todos в src/utils/handleErrors/handleErrors.ts
  for (const arg of args) {
    if (arg?.dontSendSentry) {
      return;
    }
  }

  globalVueErrorsHandler(args);
};
export const cdekConsoleWarn = (...args: any) => console.warn(...args);
export const cdekConsoleInfo = (...args: any) => console.info(...args);

import type { ComponentPublicInstance } from 'vue';
import {
  formatComponentName,
  generateComponentTrace,
} from '#root/src/utils/handleErrors/components';
import { addExceptionMechanism } from '@sentry/utils';

import type { Scope, SeverityLevel } from '@sentry/types';

import type * as SentryVue from '@sentry/vue';
import type * as SentryNode from '@sentry/node';
import type { NodeOptions } from '@sentry/node/types/types';
import { cdekConsoleError } from '@/utils/console-wrapper';
import type { Options, TracingOptions } from '@sentry/vue/types/types';

interface IClientAxiosErrorsHandlerParams {
  error: any;
  place?: string;
  metadata?: IMetadata;
}

interface ICaptureExceptionParams {
  error: any;
  level?: 'info';
  url?: string;
  status?: number;
  place?: string;
  isAxios?: boolean;
  metadata?: IMetadata;
}

type IMetadata = Record<string, string>;

let sentry: typeof SentryVue | typeof SentryNode;

// todo: расмотреть возможность внедрения единого хандлера ошибок в приложении,
//  в данный момент не очень понятно, что где нужно использовать, наверное будет
//  правильнее заложить всю логику в одной функции, которая сама будет распределять
//  нужные нам теги и т.д. по входящим параметрам
const setIsAxiosTag = (scope: Scope, isAxios: boolean) => {
  if (isAxios) {
    scope.setTag('isAxios', true);
  }
};

export const setInfoTag = (scope: Scope, lifecycleHook?: string) => {
  if (lifecycleHook) {
    scope.setTag('lifecycleHook', lifecycleHook);
  }
};

const setRequestUrlTag = (scope: Scope, url?: string) => {
  if (url) {
    scope.setTag('requestUrl', url);
  }
};

const setAxiosStatusTag = (scope: Scope, status?: number) => {
  if (status) {
    scope.setTag('axiosStatus', status);
  }
};

const setComponentNameTag = (scope: Scope, name?: string) => {
  if (name) {
    scope.setTag('componentName', name);
  }
};

export const setPlaceTag = (scope: Scope, place?: string) => {
  if (place) {
    scope.setTag('place', place);
  }
};

const setLevel = (scope: Scope, level?: SeverityLevel) => {
  if (level) {
    scope.setLevel(level);
  }
};

const setMetadata = (scope: Scope, metadata?: IMetadata) => {
  if (metadata) {
    scope.setContext('vue', metadata);
  }
};

const getSentry = async () => {
  if (!sentry) {
    sentry =
      typeof window !== 'undefined' ? await import('@sentry/vue') : await import('@sentry/node');
  }

  return sentry;
};

export const initSentry = async (
  options:
    | NodeOptions
    | Partial<
        Omit<Options, 'tracingOptions'> & {
          tracingOptions: Partial<TracingOptions>;
        }
      >,
) => {
  try {
    (await getSentry()).init(options);
  } catch (err) {
    cdekConsoleError(err, {
      dontSendSentry: true,
    });
  }
};

export const captureException = async ({
  error,
  level,
  url,
  status,
  place,
  isAxios = false,
  metadata,
}: ICaptureExceptionParams) => {
  // Capture exception in the next event loop, to make sure that all breadcrumbs are recorded in time.
  setTimeout(async () => {
    (await getSentry())?.withScope((scope: Scope) => {
      setLevel(scope, level);
      setMetadata(scope, metadata);

      setIsAxiosTag(scope, isAxios);
      setInfoTag(scope, metadata?.lifecycleHook);
      setRequestUrlTag(scope, url);
      setAxiosStatusTag(scope, status);
      setComponentNameTag(scope, metadata?.componentName);
      setPlaceTag(scope, place);

      scope.addEventProcessor((event) => {
        addExceptionMechanism(event, {
          handled: false,
        });
        return event;
      });

      sentry?.captureException(error);
    });
  });
};

export const clientAxiosErrorsHandler = ({
  error,
  place,
  metadata,
}: IClientAxiosErrorsHandlerParams) => {
  const captureParams: ICaptureExceptionParams = {
    error,
    isAxios: true,
    place,
    metadata,
  };

  if (error.response) {
    captureParams.url = error.response.config.url;

    captureParams.status = error?.response?.status;

    if (captureParams.status && captureParams.status < 500) {
      captureParams.level = 'info';
    }
  }

  captureException(captureParams);
  return true;
};

export const globalVueErrorsHandler = (
  error: any,
  vm: ComponentPublicInstance | null = null,
  lifecycleHook = '',
) => {
  const componentName = formatComponentName(vm);

  const trace = vm ? generateComponentTrace(vm) : '';
  const metadata: IMetadata = {
    componentName,
    lifecycleHook,
    trace,
  };

  if (error?.name === 'AxiosError') {
    clientAxiosErrorsHandler({ error, metadata });
    return true;
  }

  captureException({
    error,
    metadata,
  });
};

export const logException = (data: ICaptureExceptionParams) => {
  cdekConsoleError(data.error, {
    dontSendSentry: true,
  });

  captureException(data);
};
